<!--
   /**
      * officeBuilding.vue
      * @module {src/modules/public}
      * @desc 前端api
      * @author shiqiu
      * @date 2021-01-20
      */
-->
<template>
  <div>
    <el-row type="flex" justify="center" style="margin-top: 5vw;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;cursor: pointer">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10" class="margin_b_40">
                      <el-col :span="24">
                        <p class="bigTitle">酒店现状</p>
                        <p class="lineTitle"></p>
                        <p class="enTitle">The hotel the status quo</p>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10">
                      <el-col :span="24">
                        <p style="font-size: 18px;text-indent: 2em">随着酒店日趋激烈的竞争和不断攀升的客户期望，酒店装潢、客房数量、房间设施等质量竞争和价格竞争将退居二线，迫使业内人士不断寻求扩大酒店销售、改进服务质量、降低管理成本和提升客户满意度的新法宝，以增强酒店的核心竞争力。</p>
                        <p style="font-size: 18px;text-indent: 2em">目前最有效的手段就是大规模应用先进的信息化技术。变革传统意义上的酒店业竞争方式和经营管理模式，进而赢得新的竞争优势。因此，酒店的竞争将主要在智能化、个性化、信息化方面展开，智慧酒店悄然兴起，拥有一套完善的智能化体系，通过数字化与网络化实现酒店数字信息化服务技术的智慧酒店，将会成为未来酒店行业发展到新方向。</p>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-top: 20px">
                        <p style="text-align: center">
                          <img src="@/assets/hotel01.png" style="width: 80%;">
                        </p>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" style="margin-top: 10px;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;cursor: pointer">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10" class="margin_b_40">
                      <el-col :span="24">
                        <p class="bigTitle">系统优势</p>
                        <p class="lineTitle"></p>
                        <p class="enTitle">System advantage</p>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10">
                      <el-col :span="24" style="padding: 40px; margin-bottom: 20px">
                        <p style="text-align: center"><img src="@/assets/hotel02.png" style="width: 80%;"></p>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>

</template>

<script>
export default {
  name: 'Login',
  components: {},
  data () {
    return {
    }
  },
  mounted () {

  },
  watch: {

  },
  methods: {

  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
body {
  background: #fff !important;
}
</style>
